<template>
  <base-view id="privacy">
    <view-section>
      <div class="prose prose-md md:prose-lg mx-auto">
        <h1 class="text-center">Privacy Policy</h1>
        <p>
          Rocky Mountain Reserve is committed to protecting your personal and
          business contact information.
        </p>
        <p>
          The following Privacy Policy outlines Rocky Mountain Reserve practices
          regarding the collection and use of individually identifiable
          information about you (“Personal Data”) collected at this website (the
          “Site”). This Privacy Policy also represents our information statement
          as may be required by certain applicable local laws. Your privacy is
          very important to us and we take reasonable steps to ensure that the
          Personal Data we gather from your use of our Site is kept secure and
          private, and is processed in accordance with applicable laws. If and
          when the Rocky Mountain Reserve Privacy Policy changes in any material
          or substantive way, the changes will be posted at this location and
          you have the opportunity to consent to or opt-out of these changes,
          either by writing or emailing info@rmrbenefits.com. If you are
          concerned about how your information is used, please periodically
          review this Privacy Policy.
        </p>
        <h2>What information do we collect?</h2>
        <p>
          We collect information from you when you register, fill out a form, or
          make a purchase on our Site. However, you may visit our Site
          anonymously. Purchases may be made over the phone by using the contact
          information included on the Site.
        </p>
        <h2>What do we use your information for?</h2>
        <p>
          The Personal Data you provide through online collection forms may be
          used by Rocky Mountain Reserve to provide you with the products,
          materials, and services you request, as well as:
        </p>
        <ul>
          <li>
            To improve, maintain, and protect our Site and to ensure that the
            content is relevant to your needs and preferences;
          </li>
          <li>
            To enable you to view the local list prices (available for selected
            countries);
          </li>
          <li>
            To respond to any other inquiries or requests that you might make;
          </li>
          <li>To notify you about updates to our Site;</li>
          <li>
            To conduct market, customer, and general business analysis for Rocky
            Mountain Reserve internal use; and
          </li>
          <li>
            To improve and/or develop our products, materials, and services.
          </li>
        </ul>
        <p>
          The Personal Data, if any, collected through cookies may be used by
          Rocky Mountain Reserve as noted in this Privacy Policy.
        </p>
        <h2>How do we protect your information?</h2>
        <p>
          We utilize a variety of security measures to maintain the safety of
          your personal information. We maintain your private information for as
          long as deemed reasonable to complete any associated transactions.
        </p>
        <h2>Do we disclose any information to outside parties?</h2>
        <p>
          We do not sell or provide your personal information to third parties 
          except as necessary to fulfill orders or process your requests. 
          For example, if you purchase products online using a credit card, 
          we will contact the card processor or its third-party service provider 
          to process the order, and we will use a third-party shipping company 
          to ship your order or other requested items such as our print catalog 
          or literature. To enhance security, your credit card number is 
          encrypted as it is transmitted over the Internet.
        </p>
        <p>
          If you request information on products or services that have been
          reviewed or discussed on the Site, Rocky Mountain Reserve may transfer
          your information to the Rocky Mountain Reserve that provides those
          products and services according to the request you have made. We may
          provide Personal Data on a reasonable need-to-know basis to third
          parties, who are bound by nondisclosure obligations, assisting us with
          the maintenance, protection, or operation of our Site and in
          connection with website, market, or business analysis conducted on our
          behalf for our use only. We may also provide Personal Data to third
          parties, who are bound by nondisclosure obligations, assisting us with
          marketing functions and data management, on our behalf. Any changes in
          these categories of recipients will be made only after the
          notification process set forth in the beginning of this Privacy
          Policy.
        </p>
        <p>
          Rocky Mountain Reserve may share Personal Data with law enforcement or
          other entities as required by law or as we reasonably determine
          necessary to prevent harm to persons or property. Personal Data may
          also be disclosed to third parties, who are bound by nondisclosure
          obligations, in connection with a Rocky Mountain Reserve merger,
          acquisition, or sale.
        </p>
        <p>
          We may also use or provide non-identifiable data to third parties in
          order to perform market or business analysis, on our behalf and for
          our internal use. This ultimately helps us to improve our services to
          you. If you choose to register at our Site, you must provide your
          consent to the terms of this Privacy Policy, and therefore, to Rocky
          Mountain Reserve’s disclosure of your Personal Data to the third
          parties, for the purposes described in this Privacy Policy. Rocky
          Mountain Reserve uses and discloses information only in connection
          with legitimate business purposes, and therefore, if you do not
          consent, you will not be able to register at our Site.
        </p>
        <h2>Do we use cookies?</h2>
        <p>
          Yes. Cookies are small files that a site or its service provider
          transfers to your computer’s hard drive through your web browser (if
          you allow) that enables the site’s or service provider’s systems to
          recognize your browser and capture and remember certain information.
        </p>
        <p>
          We use cookies to help us remember and process the items in your
          shopping cart, understand and save your preferences for future visits,
          keep track of advertisements, and compile aggregate data about site
          traffic and site interaction so that we can offer better site
          experiences and tools in the future.
        </p>
        <p>
          If you prefer, you can choose to have your computer warn you each time
          a cookie is being sent or you can choose to turn off all cookies via
          your browser setting. Some areas and features of this site including
          e-commerce may not function properly if cookies are turned off.
        </p>
        <h2>Third-Party Links</h2>
        <p>
          Occasionally, at our discretion, we may include or offer third-party
          products or services on our Site. These third-party sites have
          separate and independent privacy policies. We, therefore, have no
          responsibility or liability for the content and activities of these
          linked sites. Nevertheless, we seek to protect the integrity of our
          site and welcome any feedback about these sites.
        </p>
        <h2>Children</h2>
        <p>
          This Site is not intended for persons under 18 years of age. We do not
          knowingly solicit or collect personal information from or about
          children or minors, and we do not knowingly market our products or
          services to children or minors.
        </p>
        <h2>Online Privacy Policy Only</h2>
        <p>
          This online privacy policy applies only to information collected
          through our Site and not to information collected offline.
        </p>
        <h2>Your Consent</h2>
        <p>
          Your use of the products and services contained or described on this
          Site including providing information to Rocky Mountain Reserve through
          our Site means that you consent to the use of any information you
          provide in accordance with the terms of this Privacy Policy.
        </p>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

export default {
  components: {
    BaseView,
    ViewSection
  }
};
</script>
